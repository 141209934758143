$(window).on('load', function () {
  $('body').removeClass('m-page--loading');
  franchiseRegionCompanyClientModal('masquerade');
  tooltips();

  // toastr.options = {
  //     "closeButton": false,
  //     "debug": false,
  //     "newestOnTop": false,
  //     "progressBar": false,
  //     "positionClass": "toast-bottom-center",
  //     "preventDuplicates": true,
  //     "onclick": null,
  //     "showDuration": "300",
  //     "hideDuration": "1000",
  //     "timeOut": "5000",
  //     "extendedTimeOut": "1000",
  //     "showEasing": "swing",
  //     "hideEasing": "linear",
  //     "showMethod": "fadeIn",
  //     "hideMethod": "fadeOut"
  // };

  moment.tz.setDefault($('#user_timezone').val());
  moment.tz.add([
    "America/Anchorage|CAT CAWT CAPT AHST AHDT YST AKST AKDT|a0 90 90 a0 90 90 90 80|012034343434343434343434343434343456767676767676767676767676767676767676767676767676767676767676767676767676767676767676767676767676767676767676|-17T00 8wX0 iA0 Qlb0 52O0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 cm0 10q0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0",
    "America/Chicago|CST CDT EST CWT CPT|60 50 50 50 50|01010101010101010101010101010101010102010101010103401010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-261s0 1nX0 11B0 1nX0 1wp0 TX0 WN0 1qL0 1cN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 11B0 1Hz0 14p0 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 RB0 8x30 iw0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0",
    "America/Denver|MST MDT MWT MPT|70 60 60 60|01010101023010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-261r0 1nX0 11B0 1nX0 11B0 1qL0 WN0 mn0 Ord0 8x20 ix0 LCN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0",
    "America/Los_Angeles|PST PDT PWT PPT|80 70 70 70|010102301010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-261q0 1nX0 11B0 1nX0 SgN0 8x10 iy0 5Wp0 1Vb0 3dB0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0",
    "America/New_York|EST EDT EWT EPT|50 40 40 40|01010101010101010101010101010101010101010101010102301010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-261t0 1nX0 11B0 1nX0 11B0 1qL0 1a10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 RB0 8x40 iv0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0",
    "Pacific/Honolulu|HST HDT HST|au 9u a0|010102|-1thLu 8x0 lef0 8Pz0 46p0"
  ]);

  toastr = function () {
    var showNotification = function (type, message, title, icon) {
      $.notify({
        // options
        // icon: icon,
        title: title,
        message: message,
      }, {
        // settings
        element: 'body',
        type: type,
        allow_dismiss: true,
        newest_on_top: true,
        placement: {
          from: "top",
          align: "right"
        },
        offset: {
          x: 20,
          y: 100
        },
        spacing: 10,
        z_index: 10000,
        delay: 1000,
        timer: 1000,
        animate: {
          enter: 'animated fadeInDown',
          exit: 'animated fadeOutUp'
        },
      });
    };
    return {
      success: function (message, title) {
        showNotification('success', message, title, 'fa fa-check-circle-o');
      },
      error: function (message) {
        showNotification('danger', message, title, 'fa fa-warning');
      }
    };
  }();

  $('#profileMenuToggle').on('click', function () {
    $('#profileMenu').toggleClass('m-dropdown--open');
  });

  var isIE = /*@cc_on!@*/false || window.navigator.msPointerEnabled;
  var isOpera = !!window.opera || /opera|opr/i.test(navigator.userAgent);
  if (isIE || isOpera) {
    location.href = '/browser-ns';
  }
});

WebFont.load({
  google: { "families": ["Poppins:300,400,500,600,700", "Roboto:300,400,500,600,700"] },
  active: function () {
    sessionStorage.fonts = true;
  }
});

function tooltips() {
  $('[data-toggle="tooltip"]').each(function () {
    var options = {
      html: $(this).data('html') ? $(this).data('html') : false,
      placement: $(this).data('placement') ? $(this).data('placement') : 'top',
      title: $(this).data('title') ? $(this).data('title') : '',
      container: $(this),
    };
    $(this).tooltip(options);
  });
}

function franchiseRegionCompanyClientReport(key) {
  return getFranchises(key);
}

function showMine(key, type) {
  return new Promise(function (resolve, reject) {
    switch (type) {
      case 'franchiseID':
        return getFranchises(key, false).then(resolve.bind(null, 'regionID'));
      case 'regionID':
        return getRegions(key, $('#loggedIn_franchiseID').val(), false).then(resolve.bind(null, 'companyID'));
      case 'companyID':
        return getCompanies(key, $('#loggedIn_regionID').val(), false).then(resolve.bind(null, 'clientID'));
      case 'clientID':
        return getClients(key, $('#loggedIn_companyID').val()).then(resolve.bind(null, null));
    }
  }).then(function (new_type) {
    $('#' + key + '-' + type).val($('#loggedIn_' + type).val()).trigger('change');
    if (new_type !== null) {
      return showMine(key, new_type);
    } else {
      $('#' + key + '-modal').modal('hide')
    }
  });
}

function franchiseRegionCompanyClientModal(key) {
  typeahead_init(key);
  $('#button-submit-' + key).on('click', function () {
    $('#form-' + key).submit();
  });

  $('#show-mine-' + key).on('click', function () {
    var promise;
    if ($('#' + key + '-franchiseID').is(':visible')) {
      showMine(key, 'franchiseID');
    } else if ($('#' + key + '-regionID').is(':visible')) {
      showMine(key, 'regionID');
    } else if ($('#' + key + '-companyID').is(':visible')) {
      showMine(key, 'companyID');
    } else if ($('#' + key + '-clientID').is(':visible')) {
      showMine(key, 'clientID');
    }
  });

  var tagRowID = $('#hidden-' + key + '-tagRowID').val();
  if (tagRowID) {
    $('.' + tagRowID).on('click', 'i.tag-delete', function () {
      removeTag(key, tagRowID, $(this));
    });

    $('.' + tagRowID).on('click', 'i.tag-clear', function () {
      $('i.tag-delete').each(function () {
        removeTag(key, tagRowID, $(this));
      });
    });
  }

  $('a[data-target="#' + key + '-modal"]').on('click', function () {
    getFranchises(key);
  });
}

function removeTag(key, tagRowID, tag) {
  var updateField = tag.data('updateField');
  $('#' + updateField).val('').trigger('change');

  removeTagsByHierarchy(key, updateField)

  if ($('.' + tagRowID + ' span.auto').length < 1) {
    $('.' + tagRowID + ' span.default').show();
    $('.' + tagRowID + ' span.clearAll').hide();
  }
}

function resetDropDown(key, dd, triggerChange) {
  triggerChange = triggerChange ? true : false;
  $('#span-' + key + '-' + dd).html('');

  if ($('#' + key + '_' + dd).length > 0) {
    $('#' + key + '_' + dd).val('');
  }

  $('#hidden-' + key + '-' + dd).val('');
  //    console.log(dd, triggerChange);
  if (triggerChange) {
    $('#hidden-' + key + '-' + dd).trigger('change');
  }
}

function removeTagsByHierarchy(key, updateField) {
  switch (updateField) {
    case 'hidden-' + key + '-franchiseID':
      $('i[data-update-field="hidden-' + key + '-franchiseID"]').closest('span').remove();
    case 'hidden-' + key + '-regionID':
      $('i[data-update-field="hidden-' + key + '-regionID"]').closest('span').remove();
    case 'hidden-' + key + '-companyID':
      $('i[data-update-field="hidden-' + key + '-companyID"]').closest('span').remove();
    case 'hidden-' + key + '-clientID':
      $('i[data-update-field="hidden-' + key + '-clientID"]').closest('span').remove();
  }
}

function handleTag(key, category, label, updateField) {
  var tagRowID = $('#hidden-' + key + '-tagRowID').val();

  if (tagRowID) {
    label = '<span class="category">' + category + ':</span> ' + label;
    //        var tagModifier = $('#hidden-' + key + '-tagModifier').val();
    var span = document.createElement('span');
    span.className = 'auto tag';
    span.innerHTML = '<i class="flaticon-cancel fs-md tag-delete" data-update-field="' + updateField + '"></i> ' + label;

    removeTagsByHierarchy(key, updateField);

    $('.' + tagRowID).append(span);
    $('.' + tagRowID + ' span.default').hide();
    $('.' + tagRowID + ' span.clearAll').show();
  }
}

function getFranchises(key, do_next) {
  do_next = (typeof (do_next) === 'undefined' || do_next) ? true : false;
  var presetFranchiseID = $('#hidden-' + key + '-franchiseID').val() * 1;
  var hideOnEmpty = $('#hidden-' + key + '-hideOnEmpty').val() * 1;
  var labelAll = $('#hidden-' + key + '-labelAll').val() * 1;
  var default_label = labelAll ? 'All Franchises' : 'Please select a Franchise';
  var data = { type: 'franchiseID', 'dropDown': 1, 'id': key + '-franchiseID', 'class': 'form-control', 'label_field': 'franchiseName', 'selected': presetFranchiseID, 'default_label': default_label }

  return $.post('/access/getAccessAssociation', data).done(function (response) {
    $('#span-' + key + '-franchiseID').html(response);

    //        if(hideOnEmpty && $('#' + key + '-franchiseID option').length < 2){
    //            $('#' + key + '-franchiseID').closest('.form-group').hide();
    //        } else {
    //            $('#' + key + '-franchiseID').closest('.form-group').show();
    //        }

    if (presetFranchiseID < 1) {
      resetDropDown(key, 'regionID');
      resetDropDown(key, 'companyID');
      resetDropDown(key, 'clientID');
    }

    $('#' + key + '-franchiseID').on('change', function () {
      var franchiseID = $(this).val();

      resetDropDown(key, 'regionID');
      resetDropDown(key, 'companyID');
      resetDropDown(key, 'clientID');

      handleTag(key, 'Franchise', $(this).find("option:selected").text(), 'hidden-' + key + '-franchiseID');

      $('#hidden-' + key + '-franchiseID').val(franchiseID).trigger('change');
      if (franchiseID && do_next) {
        getRegions(key, franchiseID);
      }
    });
  }).then(function () {
    if (presetFranchiseID > 0) {
      return getRegions(key, presetFranchiseID);
    }
  });
}

function getRegions(key, franchiseID, do_next) {
  do_next = (typeof (do_next) === 'undefined' || do_next) ? true : false;
  var presetRegionID = $('#hidden-' + key + '-regionID').val() * 1;
  var hideOnEmpty = $('#hidden-' + key + '-hideOnEmpty').val() * 1;
  var labelAll = $('#hidden-' + key + '-labelAll').val() * 1;
  var default_label = labelAll ? 'All Regions' : 'Please select a Region';
  var data = { type: 'regionID', dropDown: 1, id: key + '-regionID', class: 'form-control', label_field: 'regionName', parent_id: franchiseID, 'selected': presetRegionID, 'default_label': default_label };

  return $.post('/access/getAccessAssociation', data).done(function (response) {
    $('#span-' + key + '-regionID').html(response);

    if (hideOnEmpty && $('#' + key + '-regionID option').length < 2) {
      $('#' + key + '-regionID').closest('.row').hide();
    } else {
      $('#' + key + '-regionID').closest('.row.franchiseAdmin').show();
    }

    if (presetRegionID < 1) {
      resetDropDown(key, 'companyID');
      resetDropDown(key, 'clientID');
    }

    $('#' + key + '-regionID').on('change', function () {
      var regionID = $(this).val();

      resetDropDown(key, 'companyID');
      resetDropDown(key, 'clientID');

      handleTag(key, 'Region', $(this).find("option:selected").text(), 'hidden-' + key + '-regionID');

      $('#hidden-' + key + '-regionID').val(regionID).trigger('change');
      if (regionID && do_next) {
        getCompanies(key, regionID);
      }
    });
  }).then(function () {
    if (presetRegionID > 0) {
      return getCompanies(key, presetRegionID);
    }
  });
}

function getCompanies(key, regionID, do_next) {
  do_next = (typeof (do_next) === 'undefined' || do_next) ? true : false;
  var presetCompanyID = $('#hidden-' + key + '-companyID').val() * 1;
  var hideOnEmpty = $('#hidden-' + key + '-hideOnEmpty').val() * 1;
  var labelAll = $('#hidden-' + key + '-labelAll').val() * 1;
  var default_label = labelAll ? 'All Companies' : 'Please select a Company';
  var data = { type: 'companyID', dropDown: 1, id: key + '-companyID', class: 'form-control', label_field: 'companyName', parent_id: regionID, 'selected': presetCompanyID, 'default_label': default_label };

  return $.post('/access/getAccessAssociation', data).done(function (response) {
    $('#span-' + key + '-companyID').html(response);

    if (hideOnEmpty && $('#' + key + '-companyID option').length < 2) {
      $('#' + key + '-companyID').closest('.row').hide();
    } else {
      $('#' + key + '-companyID').closest('.row.regionAdmin').show();
    }

    if (presetCompanyID < 1) {
      resetDropDown(key, 'clientID');
    }

    $('#' + key + '-companyID').on('change', function () {
      var companyID = $(this).val();

      resetDropDown(key, 'clientID');

      handleTag(key, 'Company', $(this).find("option:selected").text(), 'hidden-' + key + '-companyID');

      $('#hidden-' + key + '-companyID').val(companyID).trigger('change');
      if (companyID && do_next) {
        getClients(key, companyID);
      }
    });
  }).then(function () {
    if (presetCompanyID > 0) {
      return getClients(key, presetCompanyID);
    }
  });
}

function getClients(key, companyID) {
  var presetClientID = $('#hidden-' + key + '-clientID').val() * 1;
  var hideOnEmpty = $('#hidden-' + key + '-hideOnEmpty').val() * 1;
  var labelAll = $('#hidden-' + key + '-labelAll').val() * 1;
  var default_label = labelAll ? 'All Agents' : 'Please select an Agent';
  var data = { type: 'clientID', dropDown: 1, id: key + '-clientID', class: 'form-control', label_field: 'clientName', parent_id: companyID, 'selected': presetClientID, 'default_label': default_label };

  return $.post('/access/getAccessAssociation', data).done(function (response) {
    $('#span-' + key + '-clientID').html(response);

    if (hideOnEmpty && $('#' + key + '-clientID option').length < 2) {
      $('#' + key + '-clientID').closest('.row').hide();
    } else {
      $('#' + key + '-clientID').closest('.row').show();
    }

    $('#' + key + '-clientID').on('change', function () {
      handleTag(key, 'Agent', $(this).find("option:selected").text(), 'hidden-' + key + '-clientID');
      $('#hidden-' + key + '-clientID').val($(this).val()).trigger('change');
    });
    return;
  });
}

function typeahead_init(key) {
  var data = { 'dropDown': 0 };

  var presetFranchiseID = $('#hidden-' + key + '-franchiseID').val() * 1;
  var presetRegionID = $('#hidden-' + key + '-regionID').val() * 1;
  var presetCompanyID = $('#hidden-' + key + '-companyID').val() * 1;

  if (presetCompanyID) {
    data.parent_id = presetCompanyID;
    data.parent_id_column = 'companyID';
  } else if (presetRegionID) {
    data.parent_id = presetRegionID;
    data.parent_id_column = 'regionID';
  } else if (presetFranchiseID) {
    data.parent_id = presetFranchiseID;
    data.parent_id_column = 'franchiseID';
  }


  $('#typeahead_' + key).typeahead({
    highlight: true,
    minLength: 3,
    limit: 10
  }, {
    name: 'client',
    display: 'clientName',
    source: function (query, syncResults, asyncResults) {
      data.type = 'clientID';
      data.search = $('#typeahead_' + key).val();
      $.post('/access/getAccessAssociation', data, function (data) {
        asyncResults(data);
      });
    }
  }, {
    name: 'company',
    display: 'companyName',
    source: function (query, syncResults, asyncResults) {
      data.type = 'companyID';
      data.search = $('#typeahead_' + key).val();
      $.post('/access/getAccessAssociation', data, function (data) {
        asyncResults(data);
      });
    }
  }, {
    name: 'region',
    display: 'regionName',
    source: function (query, syncResults, asyncResults) {
      data.type = 'regionID';
      data.search = $('#typeahead_' + key).val();
      $.post('/access/getAccessAssociation', data, function (data) {
        asyncResults(data);
      });
    }
  }/*, {
        name: 'franchise',
        display: 'franchiseName',
        source: function (query, syncResults, asyncResults) {
            data.type = 'franchiseID';
            data.search = $('#typeahead_' + key).val();
            $.post('/access/getAccessAssociation', data, function (data) {
                asyncResults(data);
            });
        }
    }*/);

  $('#typeahead_' + key).bind('typeahead:change', function (ev, text) {
    if (text === "") {
      resetDropDown(key, 'regionID', true);
      resetDropDown(key, 'companyID', true);
      resetDropDown(key, 'clientID', true);
      //            $('#hidden-' + key + '-regionID').val('').trigger('change');
      //            $('#hidden-' + key + '-companyID').val('').trigger('change');
      //            $('#hidden-' + key + '-clientID').val('').trigger('change');
    }
  });

  $('#typeahead_' + key).bind('typeahead:select', function (ev, suggestion) {
    if (suggestion.franchiseID) {
      $('#hidden-' + key + '-franchiseID').val(suggestion.franchiseID).trigger('change');
    }

    if (suggestion.regionID) {
      $('#hidden-' + key + '-regionID').val(suggestion.regionID).trigger('change');
    }

    if (suggestion.companyID) {
      $('#hidden-' + key + '-companyID').val(suggestion.companyID).trigger('change');
    }

    if (suggestion.clientID) {
      $('#hidden-' + key + '-clientID').val(suggestion.clientID).trigger('change');
    }
  });
}

function formatPhoneNumber(s) {
  var s2 = ("" + s).replace(/\D/g, '');
  var m = s2.match(/^1(\d{3})(\d{3})(\d{4})$/);
  return (!m) ? null : m[1] + "." + m[2] + "." + m[3];
}

function htmlEntities(str) {
  return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
}

function getStatusConfig(status) {
  var map = {
    'New': { 'title': 'New', 'class': 'm-badge--brand' },
    'Active': { 'title': 'New', 'class': ' m-badge--brand' },
    'Inactive': { 'title': 'Dormant', 'class': ' m-badge--info' },
    'Archived': { 'title': 'Dormant', 'class': ' m-badge--info' },
    'Dormant': { 'title': 'Dormant', 'class': ' m-badge--info' },
    'Hot': { 'title': 'Qualified', 'class': ' m-badge--success' },
    'Qualified': { 'title': 'Qualified', 'class': ' m-badge--success' },
    'Serviced': { 'title': 'Engaged', 'class': ' m-badge--focus' },
    'Engaged': { 'title': 'Engaged', 'class': ' m-badge--focus' },
    'Servicing': { 'title': 'Servicing', 'class': ' m-badge--warning' },
    'Dead': { 'title': 'Dead', 'class': ' m-badge--metal' },
    'Duplicate': { 'title': 'Duplicate', 'class': ' m-badge--metal' },
    'Application': { 'title': 'Application', 'class': ' m-badge--success' },
    'Closed Deal': { 'title': 'Closed Deal', 'class': ' m-badge--danger' },
  };

  return map[status];
}

var numberWithCommas = function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

var ucfirst = function ucfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

var ucwords = function ucwords(str) {
  str = str.toLowerCase();
  return (str + '').replace(/^(.)|\s+(.)/g, function ($1) {
    return $1.toUpperCase();
  });
};

var timeSince = function (date, useShort, seconds) {
  if (typeof seconds === 'undefined') {
    if (typeof date !== 'object') {
      date = new Date(date);
    }

    seconds = Math.floor((new Date() - date) / 1000);
  }

  if (typeof useShort !== 'boolean') {
    useShort = false;
  }

  var intervalType;

  var interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    intervalType = useShort ? 'yr' : 'year';
  } else {
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      intervalType = useShort ? 'mth' : 'month';
    } else {
      interval = Math.floor(seconds / 86400);
      if (interval >= 1) {
        intervalType = 'day';
      } else {
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
          intervalType = useShort ? 'hr' : "hour";
        } else {
          interval = Math.floor(seconds / 60);
          if (interval >= 1) {
            intervalType = useShort ? 'min' : "minute";
          } else {
            interval = seconds;
            intervalType = useShort ? 'sec' : "second";
          }
        }
      }
    }
  }

  if (interval > 1 || interval === 0) {
    intervalType += 's';
  }

  return interval + ' ' + intervalType;
};
